import React from 'react'

import { CoursesQuery } from '../query'
import Layout from '../components/layout';
import SEO from '../components/seo';
import Courses from '../components/Courses/Courses'
import CourseProvider from "../providers/CourseProvider";

const courses = () => {
    const data = CoursesQuery();
    const getUrl = () => {
        if (typeof window !== 'undefined')
            return {
                hrefLink: window.location.href,
                pathLink: window.location.pathname,
            }
    }

    return (
        <CourseProvider>
            <Layout>
                <SEO title="ContactUs" />
                <Courses courses={data.courses.edges} urlLink={getUrl()} category={data.category.edges} />
            </Layout>
        </CourseProvider>
    )
}

export default courses
